<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { limitField } from "../commons";
  const dispatch = createEventDispatcher();
  export let list;
  export let placeholder;
  export let defaultValue;
  export let name;
  export let required = false;
  export let id = "myInput";
  export let autocomplete = "off";
  export let blockSearch = false;
  export let pattern = undefined;
  let show = false;
  let isFocus = false;
  let isTouched = false;
  let selectedName = "";

  function filterFunction() {
    var txtValue;
    const input = document.querySelector(`#${id}`);
    const filter = input.value.toUpperCase();
    const div = document.querySelector(`#myDropdown${name ? name : id}`);
    const a = div.getElementsByTagName("a");
    for (let i = 0; i < a.length; i++) {
      txtValue = a[i].textContent || a[i].innerText;
      txtValue.toUpperCase().indexOf(filter) > -1
        ? (a[i].style.display = "")
        : (a[i].style.display = "none");
    }
  }

  function selectItem(res) {
    show = false;
    if (!res) return;
    selectedName = res.name;
    dispatch("setValue", res);
  }
  const showList = () =>
    show === true && isFocus ? (show = false) : (show = true);

  onMount(() => {
    defaultValue
      ? selectItem(list.find((item) => item.id == defaultValue.id))
      : null;
    document.addEventListener("keydown", handleInputFocusTransfer);
    document
      .querySelector(`#arrow${name}`)
      .addEventListener("click", () => showList());
  });

  function handleInputFocusTransfer(e) {
    const container = document.querySelector(`#myDropdown${name ? name : id}`);
    const focusableInputElements = container?.querySelectorAll("a");
    if (!focusableInputElements) return;
    const focusable = [...focusableInputElements];
    const index = focusable.indexOf(document.activeElement);
    let nextIndex = 0;
    if (e.keyCode === 38 && (e.target.id === id || list.map(lst => String(lst.id)).includes(e.target.id))) {
      e.preventDefault();
      nextIndex = index > 0 ? index - 1 : 0;
      focusableInputElements[nextIndex].focus();
    } else if (e.keyCode === 40 && (e.target.id === id || list.map(lst => String(lst.id)).includes(e.target.id))) {
      e.preventDefault();
      nextIndex = index + 1 < focusable.length ? index + 1 : index;
      focusableInputElements[nextIndex].focus();
    }
  }
</script>

<section>
  <div
    class="box-style"
    on:blur={() => setTimeout(() => (show = false), 300)}
    on:focusout={(event) => {
      isFocus = false;
      isTouched = true;
      if (
        !list
          .map((lst) => String(lst.id))
          .includes(String(event.relatedTarget?.id)) &&
        event.relatedTarget?.id !== id
      )
        setTimeout(() => (show = false), 150);
    }}
  >
    <div
      class="box-input {isTouched &&
      required &&
      (!defaultValue || !defaultValue.id)
        ? 'invalid-field'
        : ''}"
    >
      <input
        type="text"
        style="border-bottom: none;"
        {placeholder}
        {required}
         {autocomplete}
        class="form-select crop-tetx placehold__color"
        {id}
        {name}
        readonly={blockSearch}
        bind:value={selectedName}
        on:input={(ev) => (pattern ? limitField(ev, pattern) : null)}
        on:click|preventDefault={() => showList()}
        on:focus={() => {
          isTouched = false;
          isFocus = true;
          setTimeout(() => (show = true), 200);
        }}
        on:focusout={(event) =>
          event.target.value.length === 0
            ? dispatch("setValue", undefined)
            : null}
        on:keydown={(ev) => handleInputFocusTransfer(ev)}
        on:keyup={(event) => {
          if (!blockSearch) {
            if (event.key === "Backspace" || event.target.value.length === 0)
              dispatch("setValue", undefined);
            filterFunction();
          }
        }}
      />
      <i id="arrow{name}" class="material-icons sel-icon-position">
        arrow_drop_down
      </i>
    </div>
    {#if isTouched && required && (!defaultValue || !defaultValue.id)}
      <span class="help-block help-block-left field-invalid">
        {$_("This field is required")}
      </span>
    {/if}
    <div
      id="myDropdown{name ? name : id}"
      class="dropdown-content {show ? 'show' : ''}"
    >
      {#each list as item}
        <a
          href="#{item.name}"
          id={item.id}
          on:keydown={(event) =>
            event.code === "Enter" ? selectItem(item) : null}
          on:mousedown|preventDefault={() => selectItem(item)}
          on:click|preventDefault={() => selectItem(item)}>{item.name}</a
        >
      {/each}
    </div>
  </div>
</section>

<style>
  .box-style {
    position: relative;
    width: 100%;
  }
  .box-input {
    border-bottom: solid 1px;
  }
  .box-style:focus {
    border-bottom-color: var(--dynamic-color);
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background: var(--background-color) 0% 0% no-repeat padding-box;
    min-width: 230px;
    overflow: auto;
    max-height: 250px;
    z-index: 2;
    width: 100%;
    top: 100%;
    border: 1px solid var(--dropdown-border);
    border-radius: 8px;
    box-shadow: 0px 3px 6px var(--dropdown-boxShadow);
  }
  .dropdown-content a:hover,
  .dropdown-content a:focus {
    background-color: var(--hover-color);
  }
  .sel-icon-position {
    position: absolute;
    right: 0px;
    top: 9px;
    color: #686c6d;
  }
  .form-select {
    z-index: 1;
    background-image: none !important;
  }
  .dropdown-content a {
    color: var(--dropdown-letter);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .show {
    display: block;
    opacity: 1;
  }
  .crop-tetx {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .field-invalid {
    width: 100%;
    display: block;
  }
  .invalid-field {
    border-bottom: 1px solid #ff4855 !important;
  }
</style>
