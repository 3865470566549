export const environments = {
    authorizationApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('administration.', 'authorization-api.')}`
        : window.location.hostname.includes('localhost')
            ? `http://localhost:3002`
            : `https://authorization-api.qa.norelian.com`,
    guestAppApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'guestapp-api.')}`
        : window.location.hostname.includes('localhost')
            ? `http://localhost:3052`
            : `http://localhost:3052`,//`https://guestapp-api.qa.norelian.com`
    reservationsWeb: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'reservations.')}`
        : window.location.hostname.includes('localhost')
            ? `http://localhost:4210`
            : `https://reservations.qa.norelian.com`,
    reservationsApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'reservations-api.')}`
        : window.location.hostname.includes('localhost')
            ? `http://localhost:3009`
            : `https://reservations-api.qa.norelian.com`,
    filesApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'files-api.')}`
        : window.location.hostname.includes('localhost')
            ? `http://localhost:3013`
            : `https://files-api.qa.norelian.com`,
    eventsApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'events-api.')}`
        : window.location.hostname.includes('localhost')
            ? `http://localhost:3020`
            : `https://events-api.qa.norelian.com`,
    checkInApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'check-in-api.')}`
        : window.location.hostname.includes('localhost')
            ? `http://localhost:3050`
            : `https://events-api.qa.norelian.com`,
};